// images
import blogImg1 from "../images/blog/img-1.jpg";
import blogImg2 from "../images/blog/img-2.jpg";
import blogImg3 from "../images/blog/img-3.jpg";

import blogSingleImg1 from "../images/blog/blog-single/img-1.jpg";
import blogSingleImg2 from "../images/blog/blog-single/img-2.jpg";
import blogSingleImg3 from "../images/blog/blog-single/img-3.jpg";

import newsImg1 from "../images/news1.jpg";
import newsImg2 from "../images/news2.jpg";
import newsImg3 from "../images/news3.jpg";

const blogs = [
  {
    id: "1",
    title: "크리에이티브마인드, 2022년 과학·정보통신의 날 국무총리상 수상",
    screens: newsImg1,
    description:
      "Consectetur adipiscing elit. Purusout phasellus malesuada lectus.",
    author: "Marilou Kelleher",
    authorTitle: "Sineor Consultant",
    create_at: "2022.04.26",
    blogSingleImg: blogSingleImg1,
    comment: "35",
    blClass: "format-standard-image",
    link: "https://www.edaily.co.kr/news/read?newsId=02338646632300056&mediaCodeNo=257",
  },
  {
    id: "2",
    title: "[SBS ‘AI vs 인간'] AI와 인간이 작곡한 트로트 구별 될까",
    screens: newsImg2,
    description:
      "Consectetur adipiscing elit. Purusout phasellus malesuada lectus.",
    author: "Konal Biry",
    authorTitle: "Creative Director",
    create_at: "2021.02.10",
    blogSingleImg: blogSingleImg2,
    comment: "80",
    blClass: "format-standard-image",
    link: "https://www.chosun.com/culture-life/broadcast-media/2021/02/10/IANMF7Y6EVG3LMEDDMRB5KAJAE/?utm_source=naver&utm_medium=referral&utm_campaign=naver-news",
  },
  {
    id: "3",
    title: "하연 데뷔곡 만든 엔터아츠, AI 작곡 기술로 콘진원 ICT 지원사업 선정",
    screens: newsImg3,
    description:
      "Consectetur adipiscing elit. Purusout phasellus malesuada lectus.",
    author: "Aliza Anny",
    authorTitle: "Art Director",
    create_at: "2020.10.08",
    blogSingleImg: blogSingleImg3,
    comment: "95",
    blClass: "format-video",
    link: "https://www.joynews24.com/view/1305474",
  },
];
export default blogs;
