import React from "react";
import blogs from "../../api/blogs";
import { Link } from "react-router-dom";
import bImg from "../../images/blog/shape.png";
import bImg2 from "../../images/blog/shape2.png";
import SectionTitle from "../SectionTitle/SectionTitle";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const BlogSection = () => {
  return (
    <div className="wpo-blog-area-s2 section-padding section-bg">
      <div className="container">
        <SectionTitle
          subTitle={"NEWS POST"}
          Title={"크리에이티브마인드 소식"}
        />
        <div className="wpo-blog-wrap">
          <div
            className="row align-items-center"
            style={{
              marginBottom: 100,
            }}
          >
            {blogs.map((blog, bl) => (
              <div className="col-lg-4 col-md-6 col-12" key={bl}>
                <div className="blog-item">
                  <div className="blog-img">
                    <img src={blog.screens} alt="" />
                  </div>
                  <div className="blog-content">
                    <ul>
                      <li>
                        <a href="blog-single.html">{blog.create_at}</a>
                      </li>
                      {/* <li>by: {blog.author}</li> */}
                    </ul>
                    <h2>
                      <a href={blog.link} target="_blank">
                        {blog.title}
                      </a>
                    </h2>
                    <a className="arrow-btn" href={blog.link} target="_blank">
                      <i className="fi flaticon-right-arrow-1"></i>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="shape-1">
        <img src={bImg} alt="" />
      </div>
      <div className="shape-2">
        <img src={bImg2} alt="" />
      </div>
    </div>
  );
};

export default BlogSection;
