import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero1 from "../../images/slider/1.png";
import hero2 from "../../images/slider/4.png";

import "./hero.scss";

import titleVideo from "../../videos/title.mp4";

const settings = {
  dots: false,
  arrows: true,
  speed: 1200,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2500,
  fade: true,
};

const Hero = () => {
  return (
    <section className="hero hero-slider-wrapper hero-style-1">
      <div className="hero-video-area">
        <video className="hero-video" autoPlay loop muted playsInline>
          <source src={titleVideo}></source>
        </video>
        <div className="hero-video-curtain"></div>
      </div>
      <div className="hero-slider">
        <Slider {...settings}>
          <div className="slide">
            <div className="container">
              <div className="row">
                <div className="col col-lg-6 col-md-8 col-sm-12 slide-caption">
                  {/* <div className="slide-title-sub">
                    <h5>Trusted Business Partner.</h5>
                  </div> */}
                  <div className="slide-title">
                    <h2>
                      생각 너머의 영역의
                      <br />
                      창작을 돕습니다
                    </h2>
                  </div>
                  <div className="slide-subtitle">
                    <p>
                      화성학 학습 기반의 인공지능 알고리즘을 통한
                      <br />
                      빠르고 새로운 방식의 작곡
                    </p>
                  </div>
                  <div className="btns">
                    <a
                      className="hero-btn"
                      target="_blank"
                      href="https://musiaplugin.com"
                    >
                      MUSIA 홈페이지
                    </a>
                  </div>
                  {/* <div className="slider-pic">
                    <img src={hero1} alt="" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="container">
              <div className="row">
                <div className="col col-lg-8 col-md-8 col-sm-12 slide-caption">
                  {/* <div className="slide-title-sub">
                    <h5>Trusted Business Partner.</h5>
                  </div> */}
                  <div className="slide-title">
                    <h2>
                      크리에이티브마인드는
                      <br />
                      음악의 미래를 만들어 가고 있습니다
                    </h2>
                  </div>
                  <div className="slide-subtitle">
                    <p>
                      기존에 없던 새로운 창작의 방식
                      <br />
                      크리에이티브마인드와 함께하면 가능합니다
                    </p>
                  </div>
                  <div className="btns">
                    <a
                      className="hero-btn"
                      target="_blank"
                      href="https://musiaplugin.com"
                    >
                      MUSIA 홈페이지
                    </a>
                  </div>
                  {/* <div className="slider-pic">
                    <img src={hero2} alt="" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Hero;
