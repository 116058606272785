import React from "react";
import shape from "../../images/ct-shape.png";

const ContactInfo = (props) => {
  return (
    <section className="contact-info-section section-padding">
      <div className="container">
        <div className="office-info">
          <div className="row justify-content-center">
            <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
              <div className="office-info-item">
                <div className="office-info-icon">
                  <div className="icon">
                    <i className="fi flaticon-place"></i>
                  </div>
                </div>
                <div className="office-info-text">
                  <p>
                    경기도 수원시 영통구
                    <br />
                    광교중앙로248번길 7-2 D동 903호
                  </p>
                  <span>KOREA, OFFICE ADDRESS</span>
                </div>
              </div>
            </div>
            <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
              <div className="office-info-item">
                <div className="office-info-icon">
                  <div className="icon">
                    <i className="fi flaticon-email"></i>
                  </div>
                </div>
                <div className="office-info-text">
                  <p>
                    support@musiaplugin.com
                    <br />
                    &nbsp;
                  </p>
                  <span>EMAIL US</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ct-shape">
        <img src={shape} alt="shape" />
      </div>
    </section>
  );
};

export default ContactInfo;
