import React from "react";
import { Link } from "react-router-dom";
import abimg2 from "../../images/about/4.jpg";
import shape1 from "../../images/ab-shape-1.png";
import shape2 from "../../images/ab-shape-2.png";

import aboutImage from "../../images/about-image.png";

import "./about.scss";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const About = (props) => {
  return (
    <section className={`wpo-about-section section-padding ${props.abClass}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="wpo-about-wrap">
              <div className="wpo-about-img">
                <img src={aboutImage} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <div className="wpo-about-text">
              <h4 style={{ fontWeight: "700" }}>
                사용자 니즈에 기반한 인공지능 작곡
              </h4>
              <h2>
                전문 작곡가와 작곡에 진심인
                <br />
                크리에이티브마인드가 만듭니다.
              </h2>
              <p>
                크리에이티브마인드는 전문 작곡가 뿐 아니라 엔지니어까지도 작곡에
                진심입니다. 많은 예술인들의 새로운 창작을 돕기 위헤 MUSIA 엔진을
                개발하는 것에 힘씁니다. 더 많은 사람들이 MUSIA를 통해 새로운
                작곡 아이디어를 얻어 더 편하고 빠르게 작곡을 할 수 있도록
                노력합니다. MUSIA 엔진은 기존의 음악 데이터를 학습하지 않고,
                음악의 기본 원리 화성학을 학습하여 인간과 같은 방식으로 음악을
                작곡합니다. MUSIA와 함께하면 저작권에 방해받지 않는 전혀 새로운
                방식의 작곡 아이디어를 얻을 수 있습니다.
              </p>

              <div className="btns">
                <a
                  className="hero-btn hero-btn2"
                  target="_blank"
                  href="https://musiaplugin.com"
                  style={{ color: "#9694FF", paddingLeft: 0 }}
                >
                  MUSIA 홈페이지
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
